.main {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(10px + 2vmin);
  width:100%;
}

.accent {
  font-family: 'Staatliches', cursive;
  font-weight: lighter;
  margin: 5px 0;
}

.accent1 {
  color: #A8DADC;
  font-size:20px
}

.accent2 {
  color: #E63946;
  font-size:20px;
}

.accent3 {
  color: #F1FAEE;
  font-size:30px;
}

.accent4 {
  color: #457B9D;
}

.install-ios {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 5px;
}
.install-ios .down-icon{
  font-size:20px;
}

.install-android {
  text-align: right;
  position: absolute;
  top: 0;
  right : 20px;
  width: 100%;
  margin-bottom: 5px;
}